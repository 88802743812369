export default class {

    static bindGalleryActions() {

      $('.filters a:not(.all)').click(function(e){

        e.preventDefault();

        $([document.documentElement, document.body]).animate({
          scrollTop: $("#gallery").offset().top
        }, 800);

        let category = $(this).attr("rel");
        $(this).parent().siblings().find("a").removeClass("active");
        $(this).addClass("active");

        $(".videos a").removeClass("show");
        setTimeout(function (){
          $(".videos a").css("display", "none");

          $(".videos a").each(function(){
            const SELF = $(this);
            if($(this).hasClass(category)){
              SELF.css("display", "block");
              setTimeout(function (){
                SELF.addClass("show");
              }, 100);
            }
          });

        }, 300);
      });

      $('.all').click(function(e){
        e.preventDefault();
        $([document.documentElement, document.body]).animate({
          scrollTop: $("#gallery").offset().top
        }, 800);

        $(this).parent().siblings().find("a").removeClass("active");
        $(this).addClass("active");

        $(".videos a").css("display", "block");
        setTimeout(function (){
          $(".videos a").addClass("show");
        }, 100);
      });

      $('.videos a').click(function(e){
        e.preventDefault();
        let videoFile = $(this).attr("href");
        $("#modal").addClass("show");
        $('#modal video source').attr('src', videoFile);
        $("#modal video")[0].load();
        $("#modal video")[0].play();
      });

      $('#modal .btn-fechar').click(function(e){
        $("#modal").removeClass("show");
        setTimeout(function (){
          $('#modal video source').attr('src', "");
          $("#modal video")[0].load();
        },500);
      });
    }

}
