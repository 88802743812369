import { Power2 } from "gsap/gsap-core";
import { gsap } from "gsap";
import Swiper from 'swiper';
import SwiperCore, { Navigation, Autoplay } from 'swiper/core';

import Gallery from "../modules/gallery";

SwiperCore.use([Navigation, Autoplay]);

$(document).ready(function() {

  Gallery.bindGalleryActions();

  let tlStart = gsap.timeline();
  tlStart.to($('.hero'), {
    duration: 1,
    opacity: 1,
    clipPath: "circle(100% at 50% 50%)",
    ease: Power2.easeIn
  }, 0.15)
    .to($('.btn-where'), {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    }, 1.2);

  const swiperCategories = new Swiper(".swiper-categories", {
    speed: 1000,
    autoplay: {
      delay: 6000,
    },
    allowTouchMove: ($(window).width()<990) ? true : false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 5,
      },
      1400: {
        slidesPerView: 6,
        spaceBetween: 5,
      }
    }
  });

});
